<template>
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header" style="font-size:16px;">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">{{ $t('globalTrans.dashboard') }}</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">{{ $t('globalTrans.home') }}</a></li>
                <li class="breadcrumb-item active">{{ $t('globalTrans.dashboard') }}</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid dashboard-card">
          <div class="card">
            <div class="card-header">
              <div class="row">
                  <div class="col-xl-3 col-md-3 col-sm-6 col-xs-12">
                      <div class="form-group">
                          <label for="start_date">{{ $t('globalTrans.startDate') }} </label>
                          <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                      </div>
                  </div>
                  <div class="col-xl-3 col-md-3 col-sm-6 col-xs-12">
                      <div class="form-group">
                          <label for="end_date">{{ $t('globalTrans.endDate') }} </label>
                          <input type="date" id="end_date" v-model="search.end_date"  class="form-control" />
                      </div>
                  </div>
                  <div class="col-md-2">
                      <div class="form-group" style="margin-top:30px;">

                          <button v-if="authUser.role_id == 6" class="btn btn-info btn-sm" type="submit" @click.prevent="filterEntrymanSummary"><i class="fa fa-search"></i> Filter</button>
                          <button v-else class="btn btn-info btn-sm" type="submit" @click.prevent="filterSummary"><i class="fa fa-search"></i> Filter</button>
                          <div v-if="loader" class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="card-body" v-if="authUser.role_id == 6">    
              <b-overlay>
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-pink">                    
                      <div class="inner text-center">
                          <p>{{ entryManSummary ? entryManSummary.total_order : '' }}</p>                    
                          <p>Total Order</p>
                      </div>
                      <router-link to="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
            <div class="card-body" v-else>    
              <b-overlay>
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-pink">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.total_order : '' }}</p>                    
                          <p>Total Order</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-sky-blue">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.completed : '' }}</p>                    
                          <p>Completed</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date+'&query_status=14,15,16,19,20,24,25,26,31'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-danger">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.incompleted : '' }}</p>                 
                          <p>In Completed</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date+'&query_status=3,4,5,6,7,8,9,10,11,12,13,17,18,21,22,23,27,28,30'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-info-dark">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.pick_requested : '' }}</p>                   
                          <p>Pick Requested</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date+'&query_status=1'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-red-dark">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.cancel_request : '' }}</p>                   
                          <p>Cancel Request</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date+'&query_status=12'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-violet-dark">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.cancelled : '' }}</p>                  
                          <p>Canceled</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date+'&query_status=20'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-yellow-dashed">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.returnable : '' }}</p>                   
                          <p>Retrunable</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date+'&query_status=21,22'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-sky-green">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.returned : '' }}</p>                  
                          <p>Retruned</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date+'&query_status=26'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-black">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.collected : '' }}</p>                 
                          <p>Collected (BDT)</p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-violet-dark">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.deposited : '' }}</p>                   
                          <p>Deposited (BDT)</p>
                      </div>
                      <router-link target="_blank" :to="'/deposit?status=2&start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-violet">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.deposit_pending : '' }}</p>                 
                          <p>Deposit Pending(BDT)</p>
                      </div>
                      <router-link target="_blank" :to="'/deposit?status=1&start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-success">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.merchant : '' }}</p>                
                          <p>New Merchant</p>
                      </div>
                      <router-link target="_blank" :to="'/merchant?status=1'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-primary">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.service_charge : '' }}</p>                   
                          <p>Service Charge </p>
                      </div>
                      <router-link to="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-sky-blue">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.cod_charge : '' }}</p>                    
                          <p>COD </p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-pink">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.return_charge : '' }}</p>                   
                          <p>Retrun Charge </p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-sky-blue">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.weight_charge : '' }}</p>                   
                          <p>Weight Charge </p>
                      </div>
                      <router-link target="_blank" :to="'/order?date_parameter_id=4&date_type_id=2&start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-light-pink">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.paid_amount : '' }}</p>                  
                          <p>Paid Amount </p>
                      </div>
                      <router-link target="_blank" :to="'/paid?status=2&start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-2">
                    <div class="small-box bg-violet">                    
                      <div class="inner text-center">
                          <p>{{ summary ? summary.unpaid_amount : '' }}</p>                  
                          <p>Unpaid Amount (Merchant Receivable) </p>
                      </div>
                      <router-link target="_blank" :to="'/due?start_date='+search.start_date+'&end_date='+search.end_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
  </div>
</template>
<script>
  import config from '@/config'
  import moment from 'moment'
  export default {
    name:'Dashboard',
    data() {
      return {
        loader: false,
        search : {
          start_date: moment().subtract(1,'d').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD')
        },
        summary: null,
        entryManSummary: null
      }
    },
    created () {
      if(this.authUser.role_id < 3) {
        this.getSummary()
      } else if(this.authUser.role_id == 6) {
        this.getEntryManSummary()
      }
    },
    computed: {
      authUser: function () {
        return this.$store.state.authUser
      }
    },
    methods: {
      filterSummary () {
        this.getSummary()
      },
      async getSummary () {
        this.loader = true
        const response = await config.getData('/dashboard/summary', this.search)
        this.loader = false
        this.summary = response.data        
      },
      filterEntrymanSummary () {
        this.getEntryManSummary()
      },
      async getEntryManSummary () {
        this.loader = true
        const response = await config.getData('/dashboard/entryman-summary', this.search)
        this.loader = false
        this.entryManSummary = response.data        
      }
    }
  }
</script>